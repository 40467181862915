import React from 'react';

// styles
import styles from './KeyContentService.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function KeyContentService() {
    const KEY_BRAND_INFO = '/images/img_key_info.png';
    const KEY_CERTIFIED = '/images/img_key_certified.png';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Content')}>
                    <div
                        className={cx('Item')}
                        data-aos='fade-left'
                        data-aos-delay='550'
                        data-aos-duration='1000'
                    >
                        <div className={cx('Title')}>
                            쉽고 빠르게
                            <br />내 매장 직접 등록
                        </div>
                        <div className={cx('Info')}>
                            매장을 양도하고 싶은 사장님들 누구나, 간편하게
                            <br />
                            매장 정보를 등록하세요
                        </div>
                    </div>
                    <div
                        className={cx('Item', 'Img')}
                        data-aos='zoom-in'
                        data-aos-delay='750'
                        data-aos-duration='1000'
                    >
                        <img
                            src={`${KEY_BRAND_INFO}`}
                            alt='내일사장 매장등록'
                            width={510}
                            height={423}
                        />
                    </div>
                </div>
                <div className={cx('Content')}>
                    <div
                        className={cx('Item', 'Img')}
                        data-aos='zoom-in'
                        data-aos-delay='550'
                        data-aos-duration='1000'
                    >
                        <img
                            src={`${KEY_CERTIFIED}`}
                            alt='내일사장 매물검증 신청'
                            width={470}
                            height={360}
                        />
                    </div>
                    <div
                        className={cx('Item')}
                        data-aos='fade-left'
                        data-aos-delay='950'
                        data-aos-duration='1000'
                    >
                        <div className={cx('Title')}>
                            내일,사장 인증 매물/
                            <br />
                            매물 검증 신청
                        </div>
                        <div className={cx('Info')}>
                            <div className={cx('InfoTitle')}>인증 매물</div>
                            바쁘신 사장님들을 위해 전문가가 매장 등록을
                            <br />
                            도와드립니다.
                        </div>
                        <div className={cx('Info')}>
                            <div className={cx('InfoTitle')}>매물 검증 신청</div>
                            관심있는 매물에 대해서 검증을 신청할 수 있습니다.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeyContentService;
