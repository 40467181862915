import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.scss';
import './component/Utils.scss';
import Header from './component/common/Header';
import Footer from './component/common/Footer';

import Home from './component/home/Home';
import About from './component/about/About';
import Keycontent from './component/keycontent/KeyContent';

// aos
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
    useEffect(() => {
        AOS.init();
    });

    return (
        <BrowserRouter>
            <div className='Wrap'>
                <Header />
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/keycontent' element={<Keycontent />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
