import React from 'react';

// styles
import styles from './AboutWhat.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function AboutWhat() {
    const ABOUT_WHAT = '/images/img_about_what.png';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Content')}>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-duration='500'>
                        <img
                            src={`${ABOUT_WHAT}`}
                            alt='내일사장 About How'
                            width={1140}
                            height={760}
                        />
                    </div>
                    <div
                        className={cx('Item')}
                        data-aos='zoom-in'
                        data-aos-delay='250'
                        data-aos-duration='600'
                    >
                        <div className={cx('MainTitle')}>WHAT</div>
                        <div className={cx('Info')}>
                            <div className={cx('InfoTitle')}>직접 소통하고 조건을 비교하세요.</div>
                            매도자가 직접 쉽고 빠르게 매물을 등록하고 매수희망자와 자유롭게 소통하여
                            거래를 진행할 수 있습니다.
                        </div>
                        <div className={cx('Info')}>
                            <div className={cx('InfoTitle')}>전문가 인증 서비스를 제공합니다.</div>
                            전문가 인증마크를 확인하고, 더 이상 허위매물과 과장된 매출액에 속지말고
                            안전 창업하세요
                        </div>
                        <div className={cx('Info')}>
                            <div className={cx('InfoTitle')}>브랜드 인증 매물을 제공합니다.</div>
                            본사에서 직접 등록한 매장으로 더 빠르고 저렴하게 인수 창업하세요
                        </div>
                        <div className={cx('Info')}>
                            <div className={cx('InfoTitle')}>안전 결제 서비스를 제공합니다.</div>
                            구매자가 문제없이 인수절차를 완료한 후 지급 승인을 통하여 권리금을
                            입금할 수 있습니다.
                        </div>
                    </div>
                </div>
                <div
                    className={cx('Item')}
                    data-aos='fade-down'
                    data-aos-delay='550'
                    data-aos-duration='600'
                >
                    내일,사장의 제공 서비스는 안전창업을
                    <br />
                    시작할 수 있는 최소한의 기준입니다.
                </div>
            </div>
        </div>
    );
}

export default AboutWhat;
