import React from 'react';

// styles
import styles from './KeyContentBanner.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function KeyContentBanner() {
    const BANNER_IMG_1 = '/images/img_key_ban_1.png';
    const BANNER_IMG_2 = '/images/img_key_ban_2.png';
    const BANNER_IMG_3 = '/images/img_key_ban_3.png';
    const BANNER_IMG_4 = '/images/img_key_ban_4.png';
    const BANNER_IMG_5 = '/images/img_key_ban_5.png';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('BannerBg')}></div>
            <div className={cx('Inner')}>
                <div className={cx('Item')} data-aos='zoom-in' data-aos-delay='150'>
                    <div className={cx('Title')}>
                        내일,사장이 제공하는 다양한 서비스로
                        <br />
                        거래를 놓치지 마세요!
                    </div>
                    <div className={cx('Info')}>
                        개인 매물 셀프 등록부터 매물에 대한 인증, 검증 신청과 프랜차이즈 인증
                        매물까지 양도양수에 필요한 모든 과정이 앱 안에서 이루어집니다.
                    </div>
                </div>
                <div className={cx('Item', 'ImgGroup')}>
                    <img
                        src={`${BANNER_IMG_1}`}
                        alt='내일사장 앱'
                        width={200}
                        height={411}
                        data-aos='fade-up'
                        data-aos-delay='250'
                        data-aos-duration='1000'
                    />
                    <img
                        src={`${BANNER_IMG_2}`}
                        alt='내일사장 앱'
                        width={200}
                        height={411}
                        data-aos='fade-down'
                        data-aos-delay='350'
                        data-aos-duration='1000'
                    />
                    <img
                        src={`${BANNER_IMG_3}`}
                        alt='내일사장 앱'
                        width={200}
                        height={411}
                        data-aos='fade-up'
                        data-aos-delay='450'
                        data-aos-duration='1000'
                    />
                    <img
                        src={`${BANNER_IMG_4}`}
                        alt='내일사장 앱'
                        width={200}
                        height={411}
                        data-aos='fade-down'
                        data-aos-delay='550'
                        data-aos-duration='1000'
                    />
                    <img
                        src={`${BANNER_IMG_5}`}
                        alt='내일사장 앱'
                        width={200}
                        height={411}
                        data-aos='fade-up'
                        data-aos-delay='650'
                        data-aos-duration='1000'
                    />
                </div>
                <div className={cx('Item')}>
                    <div className={cx('Words')} data-aos='zoom-in' data-aos-delay='600'>
                        과도한 수수료, 기준없는 권리금에
                        <br />
                        부담스럽기만 하던 양도양수
                        <br />
                        <span>내일,사장에서 안전하게 준비하세요.</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeyContentBanner;
