import React from 'react';

// styles
import styles from './HomeTransfer.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function HomeTransfer() {
    const HOME_CHECK = '/images/img_home_check.png';
    const HOME_STORE = '/images/img_home_store.png';
    const HOME_DELIVERY = '/images/img_home_delivery.png';
    const HOME_PAY = '/images/img_home_pay.png';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Title')} data-aos='zoom-in' data-aos-delay='50'>
                    그동안 어려웠던 양도양수
                    <br />
                    간편하게 이용하세요.
                </div>
                <div className={cx('Content')}>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-delay='100'>
                        <div className={cx('Title')}>내일,사장 인증 매물</div>
                        <div className={cx('Info')}>
                            전문가가 직접 인증한
                            <br />
                            신뢰도 높은 매물을 제공합니다.
                            <br />
                            <span>매물 인증 신청 및 검증 요청 서비스 지원</span>
                        </div>
                        <img
                            src={`${HOME_CHECK}`}
                            alt='내일사장 인증매물'
                            width={112}
                            height={112}
                        />
                    </div>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-delay='200'>
                        <div className={cx('Title')}>무인매장</div>
                        <div className={cx('Info')}>
                            나 혼자도 운영 가능한 무인매장을
                            <br />
                            빠르게 찾아드립니다.
                        </div>
                        <img src={`${HOME_STORE}`} alt='무인매장' width={112} height={112} />
                    </div>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-delay='300'>
                        <div className={cx('Title')}>배달 전문점</div>
                        <div className={cx('Info')}>
                            어려운 오프라인 매장 운영 대신 ‘배달 전문점’만
                            <br />
                            검색할 수 있습니다.
                        </div>
                        <img src={`${HOME_DELIVERY}`} alt='배달전문점' width={112} height={112} />
                    </div>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-delay='400'>
                        <div className={cx('Title')}>무권리매물</div>
                        <div className={cx('Info')}>
                            부담스러운 권리금없이
                            <br />
                            바로 사장님이 될 수 있는 방법
                        </div>
                        <img src={`${HOME_PAY}`} alt='무권리매물' width={112} height={112} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeTransfer;
