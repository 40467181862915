import React from 'react';

// styles
import styles from './Footer.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function Footer() {
    const LOGO = '/images/logo.png';
    const GOOGLEPLAY_IMG = '/images/img_btn_playstore.png';
    const APPSTORE_IMG = '/images/img_btn_appstore.png';
    const SNS_KAKAO_IMG = '/images/sns_kakao.png';
    const SNS_BLOG_IMG = '/images/sns_blog.png';

    const GOOGLEPLAY_URL = process.env.REACT_APP_GOOGLEPLAY_URL;
    const APPSTORE_URL = process.env.REACT_APP_APPSTORE_URL;
    const KAKAO_URL = process.env.REACT_APP_SNS_KAKAO_URL;
    const BLOG_URL = process.env.REACT_APP_SNS_BLOG_URL;

    console.log(BLOG_URL);
    console.log(KAKAO_URL);

    const AOS_URL = () => {
        window.open(GOOGLEPLAY_URL);
    };

    const IOS_URL = () => {
        window.open(APPSTORE_URL);
    };

    const SNS_KAKAO = () => {
        window.open(KAKAO_URL);
    };

    const SNS_BLOG = () => {
        window.open(BLOG_URL);
    };

    const GOOGLEPLAY_ADDRESS = '서울특별시 용산구 한강대로 293, 4층 77호(길원동)';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <img src={`${LOGO}`} alt='내일사장 로고' width={108} height={24} />
                <div className={cx('Content')}>
                    <div className={cx('Items')}>
                        <div className={cx('Item')}>
                            <span>㈜내일사장</span> | 대표이사 박규태
                        </div>
                        <div className={cx('Item')}>사업자등록번호 : 448-81-03095</div>
                        <div className={cx('Item')}>{GOOGLEPLAY_ADDRESS}</div>
                    </div>
                    <div className={cx('Items')}>
                        {/* <div className={cx('Item')}>고객문의 : cs@sajang.app</div> */}
                        <div className={cx('Item')}>고객/제휴문의 : cs@sajang.app</div>
                    </div>
                </div>
                <div className={cx('Bottom')}>
                    <div className={cx('Copy')}>
                        <div className={cx('Sns')}>
                            <div className={cx('Items')}>
                                <div className={cx('Item')}>
                                    <img
                                        src={`${SNS_BLOG_IMG}`}
                                        onClick={SNS_BLOG}
                                        alt='블로그'
                                        width={28}
                                        height={28}
                                    />
                                </div>
                                <div className={cx('Item')}>
                                    <img
                                        src={`${SNS_KAKAO_IMG}`}
                                        onClick={SNS_KAKAO}
                                        alt='채널톡'
                                        width={20}
                                        height={21}
                                    />
                                </div>
                            </div>
                        </div>
                        Copyright ⓒ sajang. All right Reserved
                    </div>
                    <div className={cx('Download')}>
                        <img
                            src={`${APPSTORE_IMG}`}
                            onClick={IOS_URL}
                            alt='앱스토어'
                            width={173}
                            height={50}
                        />
                        <img
                            src={`${GOOGLEPLAY_IMG}`}
                            onClick={AOS_URL}
                            alt='구글플레이'
                            width={173}
                            height={50}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
