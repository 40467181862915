import React from 'react';

//components
import HomeBanner from './HomeBanner';
import HomeService from './HomeService';
import HomeTransfer from './HomeTransfer';
import HomeSystem from './HomeSystem';
import HomeConsult from './HomeConsult';

import KeyContent from '../keycontent/KeyContent';
import About from '../about/About';

// styles
import styles from './Home.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function Home() {
    return (
        <div className={cx('Wrap')}>
            <HomeBanner />
            <HomeService />
            <HomeTransfer />
            <HomeSystem />
            <KeyContent />
            <About />
            <HomeConsult />
        </div>
    );
}

export default Home;
