import React from 'react';

// styles
import styles from './HomeBanner.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function HomeBanner() {
    const HOME_BANNER = '/images/img_home_banner.png';
    const GOOGLEPLAY_IMG = '/images/img_btn_playstore.png';
    const APPSTORE_IMG = '/images/img_btn_appstore.png';

    const GOOGLEPLAY_URL = process.env.REACT_APP_GOOGLEPLAY_URL;
    const APPSTORE_URL = process.env.REACT_APP_APPSTORE_URL;

    const AOS_URL = () => {
        window.open(GOOGLEPLAY_URL);
    };

    const IOS_URL = () => {
        window.open(APPSTORE_URL);
    };

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('LeftText')}>
                    <div className={cx('MainTitle')} data-aos='fade-left' data-aos-delay='50'>
                        내일부터,
                        <br />
                        내 일은 사장님!
                        <br />
                        <span className={cx('Title')} data-aos='fade-left' data-aos-delay='150'>
                            내일,사장
                        </span>
                    </div>
                    <div className={cx('MainInfo')} data-aos='fade-left' data-aos-delay='200'>
                        세상에 없던 수수료 0원 매장 직거래 플랫폼
                    </div>
                    <div className={cx('SubInfo')} data-aos='fade-left' data-aos-delay='250'>
                        전문가가 확인한 안전한 인증 매물 서비스
                        <br /> 매물 거래 전, 전문가 검증 요청 서비스
                        <br /> 권리금 사기 걱정없는 안전 결제 서비스
                    </div>
                    <div className={cx('Download')}>
                        <img
                            src={`${APPSTORE_IMG}`}
                            onClick={IOS_URL}
                            alt='앱스토어'
                            width={140}
                            height={43}
                        />
                        <img
                            src={`${GOOGLEPLAY_IMG}`}
                            onClick={AOS_URL}
                            alt='구글플레이'
                            width={140}
                            height={43}
                        />
                    </div>
                </div>
                <img
                    src={`${HOME_BANNER}`}
                    alt='내일사장 앱'
                    width={635}
                    height={622}
                    data-aos='fade-up'
                    data-aos-delay='350'
                    data-aos-duration='1000'
                />
            </div>
        </div>
    );
}

export default HomeBanner;
