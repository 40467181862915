import React from 'react';

//components
import AboutWhy from './AboutWhy';
import AboutHow from './AboutHow';
import AboutWhat from './AboutWhat';

// styles
import styles from './About.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function About() {
    return (
        <div className={cx('Wrap')}>
            <AboutWhy />
            <AboutHow />
            <AboutWhat />
        </div>
    );
}

export default About;
