import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

// styles
import styles from './AboutHow.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function AboutHow() {
    const ABOUT_HOW = '/images/img_about_how.png';

    return (
        <div className={cx('Wrap')}>
            <MobileView className={cx('MobileInner')}>
                <div className={cx('Content')}>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-duration='500'>
                        <img
                            src={`${ABOUT_HOW}`}
                            alt='내일사장 About How'
                            width={830}
                            height={553}
                        />
                        <div className={cx('ImgInfo')}>
                            내일,사장은 대한민국 매장 양도양수 시장의
                            <br />
                            새로운 패러다임을 만들겠습니다.
                        </div>
                    </div>
                    <div
                        className={cx('Item')}
                        data-aos='zoom-in'
                        data-aos-delay='250'
                        data-aos-duration='600'
                    >
                        <div className={cx('MainTitle')}>HOW</div>
                        <div className={cx('Info')}>
                            내일,사장은 기존 부동산 중개회사가 보유한 매물뿐 아니라 간편한 등록으로
                            중개 수수료없이 자영업자들도 개인간 손쉽게 자신의 매장을 양도양수할 수
                            있습니다.
                        </div>
                        <div className={cx('Info')}>
                            내일,사장은 다년 간의 상권분석 / 프랜차이즈 / 외식 전문가로 구성되어
                            양도인과 양수인 모두 만족할 수 있는 공정하고 합리적인 권리금 산정을
                            제공합니다. 허위매물과 사기 매물에 대한 지속적인 점검으로 실제 매물로
                            거래가 이루어지도록 도와줍니다.
                        </div>
                    </div>
                </div>
            </MobileView>
            <BrowserView className={cx('Inner')}>
                <div className={cx('Content')}>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-duration='500'>
                        <img
                            src={`${ABOUT_HOW}`}
                            alt='내일사장 About How'
                            width={830}
                            height={553}
                        />
                        <div className={cx('ImgInfo')}>
                            내일,사장은 대한민국 매장 양도양수 시장의
                            <br />
                            새로운 패러다임을 만들겠습니다.
                        </div>
                    </div>
                    <div
                        className={cx('Item')}
                        data-aos='zoom-in'
                        data-aos-delay='250'
                        data-aos-duration='600'
                    >
                        <div className={cx('MainTitle')}>HOW</div>
                        <div className={cx('Info')}>
                            내일,사장은 기존 부동산 중개회사가 보유한 매물뿐 아니라 간편한 등록으로
                            중개 수수료없이 자영업자들도 개인간 손쉽게 자신의 매장을 양도양수할 수
                            있습니다.
                        </div>
                        <div className={cx('Info')}>
                            내일,사장은 다년 간의 상권분석 / 프랜차이즈 / 외식 전문가로 구성되어
                            양도인과 양수인 모두 만족할 수 있는 공정하고 합리적인 권리금 산정을
                            제공합니다. 허위매물과 사기 매물에 대한 지속적인 점검으로 실제 매물로
                            거래가 이루어지도록 도와줍니다.
                        </div>
                    </div>
                </div>
            </BrowserView>
        </div>
    );
}

export default AboutHow;
