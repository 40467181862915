import React from 'react';

// styles
import styles from './KeyContentBrand.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function KeyContentBrand() {
    const KEY_BRAND = '/images/img_key_brand.png';
    const KEY_BRAND_LOGO = '/images/img_key_brandlogo.png';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Content')}>
                    <div className={cx('Item')}>
                        <img
                            src={`${KEY_BRAND}`}
                            alt='내일사장 브랜드 인증매물'
                            width={452}
                            height={782}
                            data-aos='zoom-in'
                            data-aos-delay='450'
                            data-aos-duration='1000'
                        />
                    </div>
                    <div
                        className={cx('Item')}
                        data-aos='fade-left'
                        data-aos-delay='550'
                        data-aos-duration='1000'
                    >
                        <div className={cx('SubTitle')}>브랜드 인증매물</div>
                        <div className={cx('MainTitle')}>
                            인기 프랜차이즈를
                            <br />
                            가장 빠르게 만나는 방법
                        </div>
                        <div className={cx('Info')}>
                            인기 프랜차이즈 본사가 직접 검증한
                            <br />
                            매물을 확인할 수 있습니다.
                        </div>
                        <img
                            src={`${KEY_BRAND_LOGO}`}
                            alt='내일사장 브랜드 인증매물 로고'
                            width={688}
                            height={88}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeyContentBrand;
