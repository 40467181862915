import React from 'react';

// styles
import styles from './KeyContentPayment.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function KeyContentPayment() {
    const KEY_ESCROW = '/images/img_key_escrow.png';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Content')}>
                    <div className={cx('Item')}>
                        <div
                            className={cx('Title')}
                            data-aos='fade-left'
                            data-aos-delay='450'
                            data-aos-duration='1000'
                        >
                            모든 계약 완료된 후<br />
                            권리금 지급이 승인되는
                            <br />
                            <span>에스크로 안전 결제 서비스</span>
                        </div>
                    </div>
                    <div className={cx('Item')}>
                        <img
                            src={`${KEY_ESCROW}`}
                            alt='내일사장 에스크로 안전 결제 서비스'
                            width={442}
                            height={451}
                            data-aos='zoom-in'
                            data-aos-delay='850'
                            data-aos-duration='1000'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeyContentPayment;
