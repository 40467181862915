import React from 'react';

// styles
import styles from './HomeSystem.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function HomeSystem() {
    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Title')} data-aos='zoom-in' data-aos-delay='50'>
                    기존 양도양수 시스템과
                    <br />
                    차별화된 서비스를 제공합니다.
                </div>
                <div className={cx('Content')}>
                    <div
                        className={cx('LeftTable')}
                        data-aos='zoom-in'
                        data-aos-delay='500'
                        data-aos-duration='1000'
                    >
                        <div className={cx('Item')}>내일,사장</div>
                        <div className={cx('Item')}>특정 매장/브랜드를 추천, 권장하지 않음</div>
                        <div className={cx('Item')}>100% 실제 매물 인증서비스</div>
                        <div className={cx('Item')}>업계 최초 수수료 0%</div>
                        <div className={cx('Item')}>직거래를 통한 합리적인 거래시장 조성</div>
                    </div>
                    <div className={cx('RightTable')} data-aos='fade-up' data-aos-delay='200'>
                        <div className={cx('Item')}>기존 양도양수</div>
                        <div className={cx('Item')}>부동산 중개회사가 보유한 매물</div>
                        <div className={cx('Item')}>허위매물/사기매물 확인 불가</div>
                        <div className={cx('Item')}>중개인의 과도한 수수료</div>
                        <div className={cx('Item')}>기준없는 권리금 산정</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSystem;
