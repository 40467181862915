import React from 'react';

// styles
import styles from './KeyContentCall.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function KeyContentCall() {
    const KEY_CALL = '/images/img_key_call.png';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Content')}>
                    <div
                        className={cx('Item')}
                        data-aos='fade-left'
                        data-aos-delay='550'
                        data-aos-duration='1000'
                    >
                        <div className={cx('SubTitle')}>0505 안심전화 서비스</div>
                        <div className={cx('MainTitle')}>
                            매물 거래도 안전하게
                            <br />
                            내일,사장에서
                        </div>
                        <div className={cx('Info')}>
                            양도자-양수자간 매물 문의시
                            <br />
                            개인정보 노출없이 안전하고 간편하게
                            <br />
                            안심번호로 전화 가능
                        </div>
                    </div>
                    <div className={cx('Item')}>
                        <img
                            src={`${KEY_CALL}`}
                            alt='내일사장 0505 안심전화서비스'
                            width={452}
                            height={782}
                            data-aos='zoom-in'
                            data-aos-delay='650'
                            data-aos-duration='1000'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeyContentCall;
