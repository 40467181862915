import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

// styles
import styles from './AboutWhy.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function AboutWhy() {
    const ABOUT_WHY = '/images/img_about_why.png';

    return (
        <div className={cx('Wrap')}>
            <MobileView className={cx('MobileInner')}>
                <div className={cx('Content')}>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-duration='500'>
                        <img
                            src={`${ABOUT_WHY}`}
                            alt='내일사장 About Why'
                            width={830}
                            height={553}
                        />
                        <div className={cx('ImgInfo')}>
                            모든 자영업자분들의 아름다운 마무리와
                            <br />
                            새로운 시작을 저희 내일,사장이 함께 하겠습니다.
                        </div>
                    </div>
                    <div
                        className={cx('Item')}
                        data-aos='zoom-in'
                        data-aos-delay='250'
                        data-aos-duration='600'
                    >
                        <div className={cx('MainTitle')}>Why</div>
                        <div className={cx('SubTitle')}>
                            양도양수는 장점과 단점이 명확한 영역입니다.
                        </div>
                        <div className={cx('Info')}>
                            기존의 인력, 시설, 인테리어, 장비 등을 그대로 사용하는 경우가 많아{' '}
                            <span className={cx('Blue')}>창업 기간과 초기 비용이 크게 감소</span>
                            하고 매출 예상이 가능하여{' '}
                            <span className={cx('Blue')}>경영의 안정성이 증가</span>할 수 있습니다.
                            인기 프랜차이즈의 경우 신규 점포에 대한 거리 제한 등으로 인해 양도양수를
                            통한 신규 창업이 유일한 방법인 경우도 있습니다.
                        </div>
                        <div className={cx('Info')}>
                            반면 일부 양도인이 권리금을 올리기 위해{' '}
                            <span className={cx('Red')}>
                                허위 매출 산정, 창업 컨설턴트와 중개인의 무분별한 수수료 책정,
                            </span>{' '}
                            잘되는 매장을 파는 것은 이유가 있을 거라는 막연한 불안감 등 양도양수
                            머뭇거리게 만드는 여러가지 요소들도 존재합니다.
                        </div>
                    </div>
                </div>
            </MobileView>
            <BrowserView className={cx('Inner')}>
                <div className={cx('Content')}>
                    <div className={cx('Item')} data-aos='zoom-in' data-aos-duration='500'>
                        <img
                            src={`${ABOUT_WHY}`}
                            alt='내일사장 About Why'
                            width={830}
                            height={553}
                        />
                        <div className={cx('ImgInfo')}>
                            모든 자영업자분들의 아름다운 마무리와
                            <br />
                            새로운 시작을 저희 내일,사장이 함께 하겠습니다.
                        </div>
                    </div>
                    <div
                        className={cx('Item')}
                        data-aos='zoom-in'
                        data-aos-delay='250'
                        data-aos-duration='600'
                    >
                        <div className={cx('MainTitle')}>Why</div>
                        <div className={cx('SubTitle')}>
                            양도양수는 장점과 단점이 명확한 영역입니다.
                        </div>
                        <div className={cx('Info')}>
                            기존의 인력, 시설, 인테리어, 장비 등을 그대로 사용하는 경우가 많아{' '}
                            <span className={cx('Blue')}>창업 기간과 초기 비용이 크게 감소</span>
                            하고 매출 예상이 가능하여{' '}
                            <span className={cx('Blue')}>경영의 안정성이 증가</span>할 수 있습니다.
                            인기 프랜차이즈의 경우 신규 점포에 대한 거리 제한 등으로 인해 양도양수를
                            통한 신규 창업이 유일한 방법인 경우도 있습니다.
                        </div>
                        <div className={cx('Info')}>
                            반면 일부 양도인이 권리금을 올리기 위해{' '}
                            <span className={cx('Red')}>
                                허위 매출 산정, 창업 컨설턴트와 중개인의 무분별한 수수료 책정,
                            </span>{' '}
                            잘되는 매장을 파는 것은 이유가 있을 거라는 막연한 불안감 등 양도양수
                            머뭇거리게 만드는 여러가지 요소들도 존재합니다.
                        </div>
                    </div>
                </div>
            </BrowserView>
        </div>
    );
}

export default AboutWhy;
