import React from 'react';

//components
import KeyContentBanner from './KeyContentBanner';
import KeyContentCall from './KeyContentCall';
import KeyContentBrand from './KeyContentBrand';
import KeyContentPayment from './KeyContentPayment';
import KeyContentService from './KeyContentService';

// styles
import styles from './KeyContent.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function KeyContent() {
    return (
        <div className={cx('Wrap')}>
            <KeyContentBanner />
            <KeyContentCall />
            <KeyContentBrand />
            <KeyContentPayment />
            <KeyContentService />
        </div>
    );
}

export default KeyContent;
