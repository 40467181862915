import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

// styles
import styles from './Header.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function Header() {
    const LOGO = '/images/logo.png';

    const GOOGLEPLAY_URL = process.env.REACT_APP_GOOGLEPLAY_URL;
    const APPSTORE_URL = process.env.REACT_APP_APPSTORE_URL;

    let { pathname } = useLocation();

    const [scrollPosition, setScrollPosition] = useState(0); // 헤더 스크롤
    const [menuShow, setMenuShow] = useState(false); // 모바일 메뉴
    const [width, setWidth] = useState(window.innerWidth); // 브라우저 크기

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isBrowserWidth = width <= 767;

    const logoBtn = () => {
        setMenuShow(false);
    };

    const mainMenuBtn = () => {
        if (isMobile) {
            setMenuShow(!menuShow);
        } else {
            setMenuShow(!menuShow);
        }
    };

    const openURL = () => {
        // URL 열기
        if (isMobile && isAndroid) {
            window.open(GOOGLEPLAY_URL);
        } else if (isMobile && isIOS) {
            window.open(APPSTORE_URL);
        } else {
            window.open(GOOGLEPLAY_URL);
        }
    };

    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener('scroll', updateScroll);
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div
            className={cx(
                'Wrap',
                (isBrowserWidth && menuShow) || scrollPosition > 40 ? 'Active' : ''
            )}
        >
            <div className={cx('Inner')}>
                <div className={cx('Logo')}>
                    <Link to='/' onClick={logoBtn}>
                        <img
                            rel='preload'
                            src={`${LOGO}`}
                            alt='내일사장 로고'
                            width={108}
                            height={24}
                        />
                    </Link>
                </div>
                <div className={cx('MenuNav', menuShow ? 'Active' : '')}>
                    <Link
                        to='/about'
                        className={cx('Menu', pathname === '/about' ? 'Active' : '')}
                        onClick={mainMenuBtn}
                    >
                        <div>About us</div>
                    </Link>
                    <Link
                        to='/keycontent'
                        className={cx('Menu', pathname === '/keycontent' ? 'Active' : '')}
                        onClick={mainMenuBtn}
                    >
                        <div>주요기능</div>
                    </Link>
                    <button className={cx('DownloadBtn')} onClick={openURL}>
                        앱 다운로드
                    </button>
                </div>
                <div
                    className={cx('MenuBtn', menuShow ? 'Active' : '')}
                    onClick={() => setMenuShow(!menuShow)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
}

export default Header;
