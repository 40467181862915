import React from 'react';

// styles
import styles from './HomeService.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function HomeService() {
    const HOME_SERVICE = '/images/img_home_service.png';
    const HOME_CHECK = '/images/icon_check.png';

    return (
        <div className={cx('Wrap')}>
            <div className={cx('Inner')}>
                <div className={cx('Title')} data-aos='zoom-in' data-aos-delay='50'>
                    당신의 중요한 거래를 도와줄
                    <br />
                    서비스를 제공합니다.
                </div>
                <div className={cx('Content')}>
                    <div className={cx('LeftItem')}>
                        <div className={cx('Item')} data-aos='fade-up' data-aos-delay='200'>
                            <img src={`${HOME_CHECK}`} alt='내일사장' width={30} height={30} />
                            직거래 수수료 0원
                        </div>
                        <div className={cx('Item')} data-aos='fade-up' data-aos-delay='300'>
                            <img src={`${HOME_CHECK}`} alt='내일사장' width={30} height={30} />
                            개인정보 유출 걱정없는 안심번호
                        </div>
                        <div className={cx('Item')} data-aos='fade-up' data-aos-delay='400'>
                            <img src={`${HOME_CHECK}`} alt='내일사장' width={30} height={30} />
                            국내 유일 점포 직거래 전문 플랫폼
                        </div>
                        <div className={cx('Item')} data-aos='fade-up' data-aos-delay='500'>
                            <img src={`${HOME_CHECK}`} alt='내일사장' width={30} height={30} />
                            본사가 추천하는 브랜드 인증관
                        </div>
                    </div>
                    <div
                        className={cx('RightItem')}
                        data-aos='zoom-in'
                        data-aos-delay='150'
                        data-aos-duration='1000'
                    >
                        <img
                            src={`${HOME_SERVICE}`}
                            alt='내일사장 앱'
                            width={514}
                            height={543}
                            data-aos='fade-up'
                            data-aos-delay='550'
                            data-aos-duration='1000'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeService;
